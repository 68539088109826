export const reducer = (state, action) => {
  switch (action.type) {
    case "organisation":
      return {
        ...state,
        organisation: action.payload,
      };
    case "address":
      return {
        ...state,
        address: action.payload,
      };
    case "charity":
      return {
        ...state,
        charity: action.payload,
      };
    case "name":
      return {
        ...state,
        name: action.payload,
      };
    case "position":
      return {
        ...state,
        position: action.payload,
      };
    case "tel":
      return {
        ...state,
        tel: action.payload,
      };
    case "email":
      return {
        ...state,
        email: action.payload,
      };
    case "website":
      return {
        ...state,
        website: action.payload,
      };
    case "amount":
      return {
        ...state,
        amount: action.payload,
      };
    case "liability":
      return {
        ...state,
        liability: action.payload,
      };
    case "safeguarding":
      return {
        ...state,
        safeguarding: action.payload,
      };
    case "about":
      return {
        ...state,
        about: action.payload,
      };
    case "project":
      return {
        ...state,
        project: action.payload,
      };
    case "timeframe":
      return {
        ...state,
        timeframe: action.payload,
      };
    case "outreach":
      return {
        ...state,
        outreach: action.payload,
      };
    case "demand":
      return {
        ...state,
        demand: action.payload,
      };
    case "impact":
      return {
        ...state,
        impact: action.payload,
      };
    case "evaluation":
      return {
        ...state,
        evaluation: action.payload,
      };
    case "add-expense":
      return {
        ...state,
        expensesInputList: [...state.expensesInputList, action.payload],
      };
    case "expense-total":
      return {
        ...state,
        expenseTotal: action.payload,
      };
    case "total-income":
      return {
        ...state,
        totalIncome: action.payload,
      };
    case "total-pending-income":
      return {
        ...state,
        pendingIncome: action.payload,
      };
    case "total-confirmed-income":
      return {
        ...state,
        confirmedIncome: action.payload,
      };
  }
};
