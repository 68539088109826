const client = require('contentful').createClient({
    space: '2h7tbajan0oh',
    accessToken: 'SJSYBd7TKlpEAMIZeeHBLHbb6T94m07CtwwOANU6IoQ'
  })
  
  const getHomePage = () => client.getEntries({content_type: 'homepage'}).then(response => response.items)
  const getApplyPage = () => client.getEntries({content_type: 'applyPage'}).then(response => response.items)
  const getHistoryPage = () => client.getEntries({content_type: 'history'}).then(response => response.items)
  const getNews = () => client.getEntries({content_type: 'news'}).then(response => response.items)
  const getTrustees = () => client.getEntries({content_type: 'trustees'}).then(response => response.items)
  const getPolicy = () => client.getEntries({content_type: 'policyguidelines'}).then(response => response.items)
  const getFAQs = () => client.getEntries({content_type: 'faq', order: 'fields.order'}).then(response => response.items)
  const getFormText = () => client.getEntries({content_type: 'applicationFormText', }).then(response => response.items)
  
  const getSinglePost = slug =>
    client
      .getEntries({
        'fields.title': slug,
        content_type: 'blog'
      })
      .then(response => response.items)
  
  export { getFormText, getFAQs, getPolicy, getSinglePost, getNews, getHomePage, getTrustees, getApplyPage, getHistoryPage }