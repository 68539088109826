import React from 'react'
import BlogItems from './BlogItems'
import { getNews } from '../contentful'
import TitleBar from './TitleBar'

class Posts extends React.Component {
  state = {
    posts: []
  }

 
  componentDidMount() {
   getNews()
    .then(data => this.setState({posts: data}))
  }
  

  
  render() {
    return (
      <>
      <TitleBar title="Previous Grants" />
        <div className='content'>
      <BlogItems posts={this.state.posts}/>
      <br/>
     
    </div>
    </>
    )
  }
}
export default Posts