import React, { useState } from "react";
import Main from "./components/Main";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import History from "./components/History";
import Trustees from "./components/Trustees";
import Policy from "./components/Policy";
import News from "./components/News";
import Apply from "./components/Apply";

import Toolbar from "./components/Toolbar/Toolbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import ApplyButton from "./components/ApplyButton";

const App = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }

  let routes;
  const [pswd, setPswd] = useState("LGTWebsite");

  if (pswd === "LGTWebsite") {
    routes = (
      <>
        {" "}
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/history" component={History} />
          <Route exact path="/trustees" component={Trustees} />
          <Route exact path="/policy" component={Policy} />
          <Route exact path="/apply" component={Apply} />
          <Route path="/contact" component={Contact} />
          <Route path="/news" component={News} />
        </Switch>
        <ApplyButton />
      </>
    );
  } else {
    routes = (
      <div className="content">
        <h5>This WebSpinner site is currently under development.</h5>
        <p>Please enter your password to continue:</p>
        <input onChange={(e) => setPswd(e.target.value)} type="text" />
      </div>
    );
  }

  return (
    <Router>
      <div style={{ height: "100%" }}>
        <Toolbar drawerClickHandler={drawerToggleClickHandler} />
        <SideDrawer
          drawerClickHandler={drawerToggleClickHandler}
          show={sideDrawerOpen}
          click={drawerToggleClickHandler}
        />
        {backdrop}

        <main style={{ marginTop: "64px" }}></main>

        {routes}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
