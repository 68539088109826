import React from 'react'
import { FaTwitter, FaLinkedin } from 'react-icons/fa';


import './Toolbar.css'
import DrawerToggleButton from '../DrawerToggleButton/DrawerToggleButton'

const toolbar = props => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button" style={{paddingTop:'20px'}}>
          <DrawerToggleButton text="MENU" click={props.drawerClickHandler} />
          </div>
      <div className="toolbar__logo">
      </div>
      <div className="spacer" />
      {/* <div className="toolbar_navigation-items">
        <ul>
          <li>
            <a href="https://www.linkedin.com/in/alexandra-stacey-538602b/"><FaLinkedin/></a>
          </li>
          <li>
            <a href="https://twitter.com/RuddyRaven"><FaTwitter/></a>
          </li>
        </ul>
      </div> */}
    </nav>
  </header>
)

export default toolbar