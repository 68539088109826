import React from 'react'
import house from '../assets/house.jpg'
 
const titlebar = props => {

    return(
        <div className="title-bar">
            <h4 style={{padding:'25px'}}>{props.title}</h4>
            <div style={{width:150}}>
            <img style={{width:'100%'}} src={house}/>
            </div>
        </div>
    )
}

export default titlebar