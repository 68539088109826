import React from 'react'
import { Row, Col } from 'react-bootstrap';
import house from '../assets/house.jpg';

const Hero = (props) => {
return <div className="hero">

        <div className="hero-left">    
    <h3 className="hero-title">The Lucille Graham Trust</h3>
    </div>

   
</div>
}

export default Hero