import React, { useState } from "react";
import ApplicationForm2 from "./ApplicationForm2";

const ApplyButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div style={{ margin: 20 }}>
      <div style={{ textAlign: "center" }}>
        <a href='#' onClick={() => setModalOpen(true)} className='button'>
          Apply Online
        </a>
      </div>

      <ApplicationForm2
        openModal={modalOpen}
        closeModal={() => setModalOpen(false)}
      />
    </div>
  );
};

export default ApplyButton;
