import React, {useState, useRef} from "react";
import './Accordion.css'
import Chevron from './Chevron';
import marked from 'marked'

const Accordion = (props) => {

    const [activeState, setActiveState] = useState("")
    const [display, setDisplay] = useState('0px')
    const [iconState, setIconState] = useState('accordion-icon')

    const content = useRef(null)
   
    const toggleActive= () => {

        // setActiveState(activeState == "" ? "active" : "");
        // setDisplay(activeState === "" ? '0px' : `${content.current.scrollHeight}px` )
        // setIconState( activeState === 'active'? 'accordion-icon' : 'accordion-icon rotate' )
   
        if(activeState == ""){
            setActiveState("active")
            setDisplay(`${content.current.scrollHeight}px`)
            setIconState('accordion-icon rotate')
        
        } else {
            setActiveState("")
            setDisplay('0px')
            setIconState('accordion-icon')
        }
}

  return (
    <div>
      <p className={`accordion-title ${activeState}`} onClick={toggleActive} ><b>{props.title}</b> 
      <Chevron className={`${iconState}`} width={10} fill={'#777'} /></p>
      <div ref={content} style={{maxHeight:`${display}`}} className="accordion-content" 
      dangerouslySetInnerHTML={{__html: marked(props.content)}}
      />
    </div>
  );
};

export default Accordion;
