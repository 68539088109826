import React, { useState, useEffect } from "react";
import TitleBar from './TitleBar'
import Accordion from "./accordion/Accordion";
import { getFAQs } from '../contentful';

const iconStyle = {
  color: "rgb(139, 9, 9)",
};



const Contact = () => {

  const [faqs, setFaqs] = useState([]);

 


useEffect(() => {
  getFAQs().then(data => setFaqs(data))
}, []
)

  return (
    <>
    <TitleBar title="Contact & FAQs" />
      <div className="content">
        <div className="contact">
          <p>Please contact the Lucille Graham Trust via email at:</p>
          <p>
    
            <a href="mailto:lucillegrahamtrust@outlook.com">
              lucillegrahamtrust@outlook.com
            </a>
          </p>
          <hr/>
          
        <h3 style={{borderBottom:'1px solid gray', padding:'3px'}}>FAQs</h3>
        {faqs.map(item => 
        <div className="accordion-wrapper">
          <Accordion title={item.fields.question} content={item.fields.answer} />
          </div>
)}
          
          
          {/* <div className="social"> */}
            {/* <a style={iconStyle} href="https://www.linkedin.com/in/alexandra-stacey-538602b/"><FaLinkedin size={30}/></a>

   <a style={iconStyle} href="https://twitter.com/RuddyRaven"><FaTwitter size={30}/></a> */}
          {/* </div> */}
        </div>
      
      </div>
    </>
  );
};

export default Contact;
