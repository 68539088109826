import React, { Component } from "react";

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
  }

  today = new Date().toDateString();

  render() {
    return (
      <div style={{ padding: 55 }}>
        <h3>Lucille Graham Trust: Application Form</h3>
        <h5>Completed on:{this.today} </h5>
        <hr />

        <h5>Organisation</h5>
        <p>{this.props.state.organisation}</p>

        <h5>Address</h5>
        <p>{this.props.state.address}</p>

        <h5>Charity number</h5>
        <p>{this.props.state.charity}</p>

        <h5>Contact Name</h5>
        <p>{this.props.state.name}</p>

        <h5>Position / role</h5>
        <p>{this.props.state.position}</p>

        <h5>Telephone number</h5>
        <p>{this.props.state.tel}</p>

        <h5>Email</h5>
        <p>{this.props.state.email}</p>
        <h5>Website</h5>
        <p>{this.props.state.website}</p>

        <h5>How much are you applying for?</h5>
        <p>£{this.props.state.amount}</p>

        <h5>Do you have public liability?</h5>
        <p>{this.props.state.liability}</p>

        <h5>Safeguarding policy?</h5>
        <p>{this.props.state.safeguarding}</p>
        <hr />

        <h5>About your organisation</h5>
        <p>{this.props.state.about}</p>

        <h5>Short project description</h5>
        <p>{this.props.state.project}</p>

        <h5>Timeframe</h5>
        <p>{this.props.state.timeframe}</p>

        <h5>Who will your project reach?</h5>
        <p>{this.props.state.outreach}</p>

        <h5>Demand for your work</h5>
        <p>{this.props.state.demand}</p>

        <h5>Project impact and outcomes</h5>
        <p>{this.props.state.impact}</p>

        <h5>Evaluation</h5>
        <p>{this.props.state.evaluation}</p>
        <hr />
        <h4>Budget</h4>
        <h5>Expenses</h5>
        {this.props.expenses.map((ex) => (
          <table className='table'>
            <thead>
              <th style={{ width: "25%" }}>
                <b>Expenditure:</b>
              </th>
              <th style={{ width: "50%" }}>
                <b>Details</b>
              </th>
              <th style={{ width: "25%" }}>
                <b>Cost</b>
              </th>
            </thead>
            <tbody>
              <td>{ex.Expenditure}</td>

              <td>{ex.Details}</td>

              <td>£{ex.Cost}</td>
            </tbody>
          </table>
        ))}
        <h6>Total Expenses: £{this.props.totalExpense}</h6>
        <hr />
        <h5>Income</h5>
        {this.props.income[0].Amount !== 0 &&
          this.props.income.map((ex) => (
            <table className='table'>
              <thead>
                <th style={{ width: "25%" }}>
                  <b>Income source:</b>
                </th>
                <th style={{ width: "50%" }}>
                  <b>Confirmed?</b>
                </th>
                <th style={{ width: "25%" }}>
                  <b>Amount</b>
                </th>
              </thead>
              <tbody>
                <td>{ex.Income}</td>

                <td>{ex.Confirmed}</td>

                <td>£{ex.Amount}</td>
              </tbody>
            </table>
          ))}
        {this.props.income[0].Amount === 0 ? (
          <h6>No income given. </h6>
        ) : (
          <>
            <p>Confirmed Income: £{this.props.state.confirmedIncome}</p>
            <p>Pending Income: £{this.props.state.pendingIncome}</p>
            <h6>Total Income: £{this.props.state.totalIncome}</h6>
          </>
        )}
      </div>
    );
  }
}

export default ComponentToPrint;
