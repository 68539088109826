import React, { useEffect, useState } from "react";
import "../styles/stylesheet.css";
import Hero from "./Hero";
import { getHomePage, getFAQs } from "../contentful";
import marked from "marked";
import BlogItem from "./BlogItem";
import Accordion from "./accordion/Accordion";
import { Row, Col } from "react-bootstrap";
import house from "../assets/house.jpg";
import norman2 from '../assets/imgs/norman2.jpg'

const Main = () => {
  const [text, setText] = useState('');
  const [text2, setText2] = useState('')
  const [news, setNews] = useState([]);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    window.scrollTo(0,0)
    getHomePage().then((data) => {
      setText(data[0].fields.content)
      setText2(data[0].fields.text2)
    });
    getFAQs().then((data) => setFaqs(data));
  }, []);

  const content = marked(text);
  const content2 = marked(text2)

  return (
    <div>
      <Hero />
      <div className="content">
      <div style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: content }} />
<hr/>
        <Row>
          <Col md={6}> 
          <div style={{padding:35}}>
          <div  dangerouslySetInnerHTML={{__html: content2}} />
          <img style={{width:'100%'}} src={house} />
          </div>
          </Col>
     
         
        <Col md={6} >
          <div style={{padding:35}} >
          <img src={norman2} alt="" style={{width:'100%'}} />
          <small>Norman McCann in his personal museum, 1999<br/>
          photographed by Janet Snowman.
          </small>
          </div>
          </Col>
        
          
        </Row>
        

       
      
      </div>
    </div>
  );
};

export default Main;
