import React, { Component } from "react";
import { getApplyPage } from "../contentful";
import marked from "marked";
import TitleBar from "./TitleBar";
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

Modal.setAppElement('#root');

class Apply extends Component {
  state = {
    text: "",
    modalIsOpen: false
  };

  getMarkdownText() {
    var rawMarkup = marked(this.state.text, { sanitize: true });
    return { __html: rawMarkup };
  }

  componentDidMount() {
    getApplyPage().then((data) =>
      this.setState({ text: data[0].fields.content })
    );
  }

  render() {
    return (
      <>
      <TitleBar title="Apply" />
        <div className="content">
          <div dangerouslySetInnerHTML={this.getMarkdownText()} />

        </div>
        



      </>
    );
  }
}

export default Apply;
