import React from "react";
import marked from "marked";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import { Link } from "react-router-dom";
import {Row, Col} from 'react-bootstrap'

const BlogItem = ({ article }) => {
  const { title, image, content, author } = article.fields;
  const postBody = marked(content);
  const excerpt = postBody.split(" ").slice(0, 45).join(" ");
  return (
    <div className="news-article">
    <h3>{title}</h3>
   <hr></hr>
      <section dangerouslySetInnerHTML={{ __html: postBody  }} />

{image && <img style={{height:'200px'}} src={image.fields.file.url} />}

<hr></hr>
      {/* <div>
        <small>Share this post: </small>
        <TwitterShareButton
          url={window.location}
          children={<TwitterIcon size={24} />}
        />
        <LinkedinShareButton
          url={window.location}
          children={<LinkedinIcon size={24} />}
        />
        <FacebookShareButton
          url={window.location}
          children={<FacebookIcon size={24} />}
        />
      </div> */}
    </div>
  );
};

export default BlogItem;
