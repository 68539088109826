import React, { useEffect, useState } from "react";
import { getHistoryPage } from "../contentful";
import marked from "marked";
import { Row, Col } from "react-bootstrap";
import Titlebar from "./TitleBar";
import lucille from "../assets/imgs/lucille.jpg";
import norman from "../assets/imgs/norman.jpg";

const History = () => {
  const [text, setText] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getHistoryPage().then((data) => setText(data));
  }, []);

  return (
    <div>
      <Titlebar title="History" />

      <div className="content">
        {text.map((content) => {
          const para1 = marked(content.fields.content);
          const para2 = marked(content.fields.content2);
          return (
            <div>
              <Row>
                <Col md={10}>
                  <div
                    className="history-text"
                    dangerouslySetInnerHTML={{ __html: para1 }}
                  />
                </Col>
                <Col md={2}>
                  <img src={lucille} style={{ width: "100%" }} alt="" />
                  <small>
                    Lucille Graham
                    <br />
                    photographed by Russell Sedgwick.
                  </small>
                </Col>
              </Row>
              <Row style={{ marginTop: 45 }}>
                <Col md={2}>
                  <img src={norman} alt="" style={{ width: "100%" }} />
                  <small>
                    Norman McCann, stage name Paul Manning, early in his career
                    <br />
                    unknown photographer.{" "}
                  </small>
                </Col>
                <Col md={10}>
                  <div
                    className="history-text"
                    dangerouslySetInnerHTML={{ __html: para2 }}
                  />
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default History;
