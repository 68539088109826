import React, { Component } from 'react';
import { getPolicy } from '../contentful';
import marked from 'marked';
import TitleBar from './TitleBar';

class Policy extends Component {
    state = {
        text: ''
    }

    componentDidMount(){
        getPolicy().then(data => this.setState({text: data[0].fields.content}))
    }

    getMarkdownText() {
        var rawMarkup = marked(this.state.text, {sanitize: true});
        return { __html: rawMarkup };
      }

    render(){
        return(
            <>
            <TitleBar title="Policy & Guidelines" />
            <div className="content">
            <div style={{textAlign:'justify'}} dangerouslySetInnerHTML={this.getMarkdownText()} />
            </div>
            </>
        )
    }
}

export default Policy
