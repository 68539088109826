import React, { useEffect, useState } from 'react'
import { getTrustees } from '../contentful'
import TitleBar from './TitleBar'
import marked from 'marked';

const Trustees = () => {
const [trustees, setTrustees] = useState([])




useEffect(() => {
getTrustees().then(data => setTrustees(data))
}, [])


   

        return(
          <>
          <TitleBar title="Trustees" />
            <div className="content text-center">
                <div className="trustees-wrapper">
              {  trustees.map(trustee =>  {
                  const text = marked(trustee.fields.content)

                return <div dangerouslySetInnerHTML={{__html: text}} ></div>
              }
            )}

              </div>

            </div>
            </>
        )
    
}


export default Trustees;