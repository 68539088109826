import React from "react";
import { Row, Col } from "react-bootstrap";

const style = {
  minHeight: "25px",
  color: "white",
  background: "black",
  textAlign: "center",
  padding: 20,
};
const Footer = (props) => {
  return (
    <div style={style}>
      <small>
        Company Number 2757857
        <br />
        Registered Charity Number 1017034
        <br />
        93 Aldwick Road, Bognor Regis, West Sussex, PO21 2NW{" "}
      </small>
      <small>(Please note that this is not the correspondence address.)</small>
      <br />
      <small> Copyright © 2021 The Lucille Graham Trust</small>
      <br />
      <small>
        Website designed and built by Adam Woolf @{" "}
        <a href='https://www.webspinner.eu'>WebSpinner.eu</a>
      </small>
    </div>
  );
};

export default Footer;
