import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaLinkedin } from "react-icons/fa";
import DrawerToggleButton from '../DrawerToggleButton/DrawerToggleButton'

import "./SideDrawer.css";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>

      <nav className={drawerClasses}>
        <div className="close-drawer" >
      <DrawerToggleButton text="CLOSE" click={props.drawerClickHandler}  />
      </div>
        <div className="header">

        
          <h4>The Lucille Graham Trust</h4>

          <hr></hr>
        </div>
        <ul>
          <li>
            <Link onClick={props.click} className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={props.click} className="nav-link" to="/history">
              History{" "}
            </Link>
          </li>
          <li>
            <Link onClick={props.click} className="nav-link" to="/trustees">
              Trustees
            </Link>
          </li>
          <li>
            <Link onClick={props.click} className="nav-link" to="/policy">
              Policy & Guidelines
            </Link>
          </li>
          <li>
            <Link onClick={props.click} className="nav-link" to="/apply">
              Apply{" "}
            </Link>
          </li>
          <li>
            <Link onClick={props.click} className="nav-link" to="/news">
              Previous Grants
            </Link>
          </li>

          <li>
            <Link onClick={props.click} className="nav-link" to="/contact">
              Contact & FAQs
            </Link>
          </li>
        </ul>

        {/* <div className="social">
   
            <a href="https://www.linkedin.com/in/alexandra-stacey-538602b/"><FaLinkedin size={30}/></a>
         
            <a href="https://twitter.com/RuddyRaven"><FaTwitter size={30}/></a>
       

              </div> */}
      </nav>
    </div>
  );
};

export default sideDrawer;
