import React, { useState, useEffect, useReducer, useRef } from "react";
import TitleBar from "./TitleBar";
import Modal from "react-modal";
import { getFormText } from "../contentful";
import marked from "marked";
import { reducer } from "./FormReducer";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import { FaSave } from "react-icons/fa";

const ApplicationForm2 = (props) => {
  const [expConfirmed, setExpConfirmed] = useState(true);
  const [incConfirmed, setIncConfirmed] = useState(true);

  const componentRef = useRef();
  const [state, dispatch] = useReducer(reducer, {
    organisation: "",
    address: "",
    charity: "",
    name: "",
    position: "",
    tel: "",
    email: "",
    website: "",
    amount: "",
    liability: "",
    safeguarding: "",
    about: "",
    project: "",
    timeframe: "",
    outreach: "",
    demand: "",
    impact: "",
    evaluation: "",
    expensesInputList: [],
    totalIncome: 0,
    confirmedIncome: 0,
    pendingIncome: 0,
  });

  const handleTextInput = (e) => {
    window.localStorage.setItem(e.target.name, e.target.value);
    dispatch({ type: e.target.name, payload: e.target.value });
  };

  const [formText, setFormText] = useState("");
  const getInfoFromLocalStorage = () => {
    dispatch({
      type: "organisation",
      payload: window.localStorage.organisation,
    });
    dispatch({ type: "address", payload: window.localStorage.address });
    dispatch({ type: "charity", payload: window.localStorage.charity });
    dispatch({ type: "name", payload: window.localStorage.name });
    dispatch({ type: "position", payload: window.localStorage.position });
    dispatch({ type: "tel", payload: window.localStorage.tel });
    dispatch({ type: "email", payload: window.localStorage.email });
    dispatch({ type: "website", payload: window.localStorage.website });
    dispatch({ type: "amount", payload: window.localStorage.amount });
    dispatch({ type: "liability", payload: window.localStorage.liability });
    dispatch({
      type: "safeguarding",
      payload: window.localStorage.safeguarding,
    });
    dispatch({ type: "about", payload: window.localStorage.about });
    dispatch({ type: "project", payload: window.localStorage.project });
    dispatch({ type: "timeframe", payload: window.localStorage.timeframe });
    dispatch({ type: "outreach", payload: window.localStorage.outreach });
    dispatch({ type: "demand", payload: window.localStorage.demand });
    dispatch({ type: "impact", payload: window.localStorage.impact });
    dispatch({ type: "evaluation", payload: window.localStorage.evaluation });
  };

  useEffect(() => {
    getFormText().then((data) => setFormText(data[0].fields.content));
    getInfoFromLocalStorage();
  }, []);

  const [aboutWordsLimit, setAboutWordsLimit] = useState(false);
  const [overflowAbout, setOverflowAbout] = useState(0);

  const saveAbout = (e) => {
    localStorage.setItem(e.target.name, e.target.value);
    dispatch({ type: e.target.name, payload: e.target.value });
    if (e.target.value.split(" ").length > 200) {
      setAboutWordsLimit(true);
      setOverflowAbout(e.target.value.split(" ").length - 200);
    } else {
      setAboutWordsLimit(false);
    }
  };

  const [descriptionWordsLimit, setDescriptionWordsLimit] = useState(false);
  const [overflowDescription, setOverflowDescription] = useState(0);
  const saveProject = (e) => {
    localStorage.setItem(e.target.name, e.target.value);
    dispatch({ type: e.target.name, payload: e.target.value });
    if (e.target.value.split(" ").length > 500) {
      setDescriptionWordsLimit(true);
      setOverflowDescription(e.target.value.split(" ").length - 500);
    } else {
      setDescriptionWordsLimit(false);
    }
  };

  const [timeframeWordsLimit, setTimeframeWordsLimit] = useState(false);
  const [overflowTimeframe, setOverflowTimeframe] = useState(0);
  const saveTimeframe = (e) => {
    localStorage.setItem(e.target.name, e.target.value);
    dispatch({ type: e.target.name, payload: e.target.value });
    if (e.target.value.split(" ").length > 50) {
      setTimeframeWordsLimit(true);
      setOverflowTimeframe(e.target.value.split(" ").length - 50);
    } else {
      setTimeframeWordsLimit(false);
    }
  };

  const [outreachWordsLimit, setOutreachWordsLimit] = useState(false);
  const [overflowOutreach, setOverflowOutreach] = useState(0);
  const saveOutreach = (e) => {
    localStorage.setItem(e.target.name, e.target.value);
    dispatch({ type: e.target.name, payload: e.target.value });
    if (e.target.value.split(" ").length > 200) {
      setOutreachWordsLimit(true);
      setOverflowOutreach(e.target.value.split(" ").length - 200);
    } else {
      setOutreachWordsLimit(false);
    }
  };

  const [demandWordsLimit, setDemandWordsLimit] = useState(false);
  const [overflowDemand, setOverflowDemand] = useState(0);
  const saveDemand = (e) => {
    localStorage.setItem(e.target.name, e.target.value);
    dispatch({ type: e.target.name, payload: e.target.value });
    if (e.target.value.split(" ").length > 200) {
      setDemandWordsLimit(true);
      setOverflowDemand(e.target.value.split(" ").length - 200);
    } else {
      setDemandWordsLimit(false);
    }
  };

  const [impactWordsLimit, setImpactWordsLimit] = useState(false);
  const [overflowImpact, setOverflowImpact] = useState(0);
  const saveImpact = (e) => {
    localStorage.setItem(e.target.name, e.target.value);
    dispatch({ type: e.target.name, payload: e.target.value });
    if (e.target.value.split(" ").length > 200) {
      setImpactWordsLimit(true);
      setOverflowImpact(e.target.value.split(" ").length - 200);
    } else {
      setImpactWordsLimit(false);
    }
  };

  const [evaluationWordsLimit, setEvaluationWordsLimit] = useState(false);
  const [overflowEvaluation, setOverflowEvaluation] = useState(0);
  const saveEvaluation = (e) => {
    localStorage.setItem(e.target.name, e.target.value);
    dispatch({ type: e.target.name, payload: e.target.value });
    if (e.target.value.split(" ").length > 200) {
      setEvaluationWordsLimit(true);
      setOverflowEvaluation(e.target.value.split(" ").length - 200);
    } else {
      setEvaluationWordsLimit(false);
    }
  };

  // DYNAMIC EXPENSES INPUTS
  const [expensesInputList, setExpensesInputList] = useState([
    { Expenditure: "", Details: "", Cost: 0, Clicked: false },
  ]);
  const [totalExpense, setTotalExpense] = useState(0);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...expensesInputList];
    list[index][name] = value;

    setExpensesInputList(list);
    // localStorage.setItem(name + index, value);
  };

  // handle total update
  const handleTotalExp = (event, exp, index) => {
    event.preventDefault();

    const array = [...expensesInputList];
    array[index].Clicked = true;
    setExpensesInputList(array);

    const runningTotal = array.reduce((acc, item) => {
      acc += +item.Cost;
      return acc;
    }, 0);
    setTotalExpense(runningTotal);
    localStorage.setItem("totalExp", runningTotal.toString());
    setExpConfirmed(true);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (event, exp, index) => {
    event.preventDefault();

    const list = [...expensesInputList];
    list.splice(index, 1);
    setExpensesInputList(list);
    let total = totalExpense - +exp.Cost;
    setTotalExpense(total);
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    e.preventDefault();
    setExpensesInputList([
      ...expensesInputList,
      { Expenditure: "", Details: "", Cost: 0, Clicked: false },
    ]);
  };

  // DYNAMIC INCOME INPUTS
  const [incomeInputList, setIncomeInputList] = useState([
    { Income: "", Confirmed: "", Amount: 0, Clicked: false },
  ]);
  const [totalIncome, setTotalIncome] = useState(0);

  // handle input change
  const handleIncomeInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...incomeInputList];
    list[index][name] = value;
    setIncomeInputList(list);
    console.log(list);
    localStorage.setItem(name + index, value);
  };

  // handle total update
  const handleTotalInc = (e, exp, index) => {
    e.preventDefault();

    const array = [...incomeInputList];
    array[index].Clicked = true;
    setIncomeInputList(array);
    const runningTotal = array.reduce((acc, item) => {
      acc += +item.Amount;
      return acc;
    }, 0);

    const confirmed = array.reduce((acc, item) => {
      if (item.Confirmed === "Confirmed") {
        acc += +item.Amount;
        return acc;
      }
      return acc;
    }, 0);

    const pending = array.reduce((acc, item) => {
      if (item.Confirmed === "Pending") {
        acc += +item.Amount;
        return acc;
      }
      return acc;
    }, 0);

    setTotalIncome(runningTotal);
    dispatch({ type: "total-income", payload: runningTotal });
    dispatch({ type: "total-confirmed-income", payload: confirmed });
    dispatch({ type: "total-pending-income", payload: pending });
    localStorage.setItem("totalInc", runningTotal.toString());
    setIncConfirmed(true);
  };

  // handle click event of the Remove button
  const handleRemoveIncClick = (event, exp, index) => {
    event.preventDefault();

    const list = [...incomeInputList];
    list.splice(index, 1);
    setIncomeInputList(list);
    let total = totalIncome - +exp.Cost;
    setTotalIncome(total);
  };

  // handle click event of the Add button
  const handleAddIncClick = (e) => {
    e.preventDefault();
    setIncomeInputList([
      ...incomeInputList,
      { Income: "", Confirmed: "", Amount: 0, Clicked: false },
    ]);
  };

  return (
    <>
      <Modal
        isOpen={props.openModal}
        closeTimeoutMS={200}
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, .5)" },
        }}
      >
        <TitleBar title='Apply Online' />

        <div className='content' id='pdf-view'>
          <div
            dangerouslySetInnerHTML={{ __html: marked(formText) }}
            style={{ textAlign: "center" }}
          ></div>
          <hr></hr>

          <div>
            <h4 style={{ marginBottom: 25 }}>Application Form</h4>
            <form
              // onSubmit={onSubmit}
              className='application-form'
              // action='https://formspree.io/f/xzbkqrkl'
              // method='POST'
              // id='form'
            >
              Organisation
              <input
                onChange={handleTextInput}
                required
                type='text'
                value={state.organisation}
                name='organisation'
              />
              Address
              <textarea
                rows={4}
                onChange={handleTextInput}
                required
                value={state.address}
                name='address'
              />
              Charity / company no.
              <input
                onChange={handleTextInput}
                value={state.charity}
                type='text'
                name='charity'
                required
              />
              Contact name
              <input
                onChange={handleTextInput}
                value={state.name}
                required
                type='text'
                name='name'
              />
              Position / role
              <input
                required
                onChange={handleTextInput}
                value={state.position}
                name='position'
              />
              Telephone
              <input
                required
                onChange={handleTextInput}
                value={state.tel}
                type='text'
                name='tel'
              />
              Email
              <input
                required
                onChange={handleTextInput}
                value={state.email}
                type='email'
                name='email'
              />
              Website
              <input
                required
                onChange={handleTextInput}
                value={state.website}
                type='text'
                name='website'
              />
              <hr />
              How much are you applying for (£)?
              <input
                required
                onChange={handleTextInput}
                value={state.amount}
                type='text'
                name='amount'
              />
              <div>
                <hr />
                <p>Please confirm whether your organisation has:</p>
                <span style={{ marginRight: "5px" }}>
                  Public Liability Insurance (please answer yes/no):
                </span>
                <input
                  required
                  onChange={handleTextInput}
                  value={state.liability}
                  type='text'
                  name='liability'
                />
              </div>
              <div>
                <span style={{ marginRight: "5px" }}>
                  Safeguarding policy (please answer yes/no) :
                </span>
                <input
                  required
                  onChange={handleTextInput}
                  value={state.safeguarding}
                  type='text'
                  name='safeguarding'
                />
              </div>
              <b>About your organisation</b>
              <p>
                Please tell us about your organisation and describe relevant
                previous experience and track record of working in your field.
                (200 words max).
              </p>
              {aboutWordsLimit && (
                <p style={{ color: "red" }}>
                  You have exceeded the word limit by {overflowAbout} words.
                </p>
              )}
              <textarea
                required
                onChange={saveAbout}
                value={state.about}
                rows={10}
                name='about'
              ></textarea>
              <b>Short project description</b>
              <p>
                Please tell us what you'd like to do; explain the proposed
                activities, target groups, partners, location/setting and
                outputs. (500 words max).
              </p>
              {descriptionWordsLimit && (
                <p style={{ color: "red" }}>
                  You have exceeded the word limit by {overflowDescription}{" "}
                  words.
                </p>
              )}
              <textarea
                required
                onChange={saveProject}
                value={state.project}
                rows={15}
                name='project'
              ></textarea>
              <b>Timeframe</b>
              <p>
                Please explain when the project will take place. (50 words max).
              </p>
              {timeframeWordsLimit && (
                <p style={{ color: "red" }}>
                  You have exceeded the word limit by {overflowTimeframe} words.
                </p>
              )}
              <textarea
                required
                onChange={saveTimeframe}
                value={state.timeframe}
                name='timeframe'
              ></textarea>
              <b>Who will your project reach?</b>
              <p>
                Please tell us about the participants or audience members you
                hope to engage and explain how you will reach them (e.g.
                participant recruitment plans, audience / marketing plans). Also
                tell us how many people you hope to reach. (200 words max).
              </p>
              {outreachWordsLimit && (
                <p style={{ color: "red" }}>
                  You have exceeded the word limit by {overflowOutreach} words.
                </p>
              )}
              <textarea
                required
                onChange={saveOutreach}
                value={state.outreach}
                rows={10}
                name='outreach'
              ></textarea>
              <b>Demand for your work</b>
              <p>
                How do you know there is demand for your work? How do you know
                your project is needed? (200 words max).
              </p>
              {demandWordsLimit && (
                <p style={{ color: "red" }}>
                  You have exceeded the word limit by {overflowDemand} words.
                </p>
              )}
              <textarea
                required
                onChange={saveDemand}
                value={state.demand}
                rows={10}
                name='demand'
              ></textarea>
              <b>Project impact and outcomes</b>
              <p>
                What overall impact do you hope to achieve? What outcomes will
                your project have?
                <br />
                <em>
                  An outcome is a change in skills, attitude or behaviour and
                  must be measurable.&nbsp;
                </em>
                (200 words max).
              </p>
              {impactWordsLimit && (
                <p style={{ color: "red" }}>
                  You have exceeded the word limit by {overflowImpact} words.
                </p>
              )}
              <textarea
                required
                onChange={saveImpact}
                value={state.impact}
                rows={10}
                name='impact'
              ></textarea>
              <b>Evaluation</b>
              <p>
                Please describe how you will evaluate your project. (200 words
                max).
                <br />
                <em>
                  {" "}
                  NB it is not essential for projects to have an external
                  evaluator or to produce lengthy evaluation documents. However,
                  we would like to see that you have processes in place to
                  collect qualitative and quantative data / feedback in order to
                  assess whether your project successfully achieves the desired
                  outcomes.
                </em>
              </p>
              {evaluationWordsLimit && (
                <p style={{ color: "red" }}>
                  You have exceeded the word limit by {overflowEvaluation}{" "}
                  words.
                </p>
              )}
              <textarea
                required
                onChange={saveEvaluation}
                value={state.evaluation}
                rows={10}
                name='evaluation'
              ></textarea>
              <b>Project budget</b>
              <p>
                <b>
                  Please note: For security, data in this section does not get
                  saved between sessions.
                </b>{" "}
                <br />
                If you close this window before submitting, you will need to
                fill in the budget section again.
              </p>
              <p>
                <b>Expenses</b>
              </p>
              {expensesInputList.map((x, i) => {
                return (
                  <div key={i}>
                    <div className='expenses-inputs'>
                      <input
                        required
                        style={{ width: "35%" }}
                        name={`Expenditure`}
                        placeholder='Expenditure item'
                        // value={x.Expenditure}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      <input
                        required
                        style={{ width: "40%" }}
                        name={`Details`}
                        placeholder='Details'
                        // value={x.Details}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      <input
                        style={{ width: "11%" }}
                        type='number'
                        name={`Cost`}
                        min={0}
                        placeholder='Cost'
                        value={x.Cost}
                        onChange={(e) => {
                          handleInputChange(e, i);
                          setExpConfirmed(false);
                        }}
                        // onBlur={() => handleTotalExp(x, i)}
                      />
                    </div>
                    <div style={{ marginBottom: 10, marginTop: 5 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {expensesInputList.length !== 1 && (
                            <button
                              style={{ marginRight: 5 }}
                              onClick={(e) => handleRemoveClick(e, x, i)}
                            >
                              Remove
                            </button>
                          )}

                          <button onClick={handleAddClick}>
                            Add another expense
                          </button>
                        </div>
                        {expensesInputList.length - 1 === i && (
                          <button
                            disabled={expConfirmed}
                            style={{ marginRight: 5 }}
                            onClick={(e) => handleTotalExp(e, x, i)}
                          >
                            Confirm
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              <br />
              <div className='text-right'>
                <span>
                  <strong>Total projected expenses: </strong>
                </span>
                <input
                  value={"£" + totalExpense}
                  type='text'
                  name='total income'
                  placeholder='automatically updates'
                />
              </div>
              <p>
                <b>Income</b>
              </p>
              <p>Please include this application as a source of income.</p>
              {incomeInputList.map((x, i) => (
                <div>
                  <div className='expenses-inputs'>
                    <input
                      style={{ width: "35%" }}
                      name={`Income`}
                      placeholder='Income source'
                      value={x.Income}
                      onChange={(e) => {
                        handleIncomeInputChange(e, i);
                      }}
                    />
                    {/* <input
                      style={{ width: "40%" }}
                      name={`Confirmed`}
                      placeholder='Confirmed?'
                      // value={x.Confirmed}
                      onChange={(e) => {
                        handleIncomeInputChange(e, i);
                      }}
                    /> */}
                    <select
                      name='Confirmed'
                      onChange={(e) => {
                        handleIncomeInputChange(e, i);
                      }}
                    >
                      <option value='please choose'>
                        Indicate income status
                      </option>
                      <option value='Confirmed'>Confirmed</option>
                      <option value='Pending'>Pending</option>
                    </select>
                    <input
                      style={{ width: "11%" }}
                      type='number'
                      name={`Amount`}
                      min={0}
                      placeholder='Amount'
                      value={x.Amount}
                      onChange={(e) => {
                        handleIncomeInputChange(e, i);
                        setIncConfirmed(false);
                      }}
                      // onBlur={(e) => handleTotalInc(e, x, i)}
                    />
                  </div>
                  <div style={{ marginBottom: 10, marginTop: 5 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {incomeInputList.length !== 1 && (
                          <button
                            style={{ marginRight: 5 }}
                            onClick={(e) => handleRemoveIncClick(e, x, i)}
                          >
                            Remove
                          </button>
                        )}

                        <button onClick={handleAddIncClick}>
                          Add another income
                        </button>
                      </div>
                      {incomeInputList.length - 1 === i && (
                        <button
                          disabled={
                            incConfirmed || !incomeInputList[i].Confirmed
                          }
                          style={{ marginRight: 5 }}
                          onClick={(e) => handleTotalInc(e, x, i)}
                        >
                          Confirm
                        </button>
                      )}
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
              <br />
              <div>
                <p>Total Pending Income: £{state.pendingIncome}</p>
                <p>Total Confirmed Income: £{state.confirmedIncome} </p>
              </div>
              <div className='text-right'>
                <span>
                  <strong>Total projected income: </strong>
                </span>
                <input
                  value={"£" + totalIncome}
                  required
                  type='text'
                  name='total income'
                  placeholder='automatically updates'
                />
              </div>
              <p>
                <em>
                  You may send us a separate budget sheet instead, if you wish,
                  as long as it contains a breakdown of costs. <br /> It should
                  be submitted together with this form to{" "}
                  <a href='mailto:lucillegrahamtrust@outlook.com'>
                    lucillegrahamtrust@outlook.com
                  </a>
                  .
                </em>
              </p>
              <hr />
            </form>
            <div className='form-buttons'>
              <ReactToPrint
                documentTitle='Lucille_Graham_Trust_Application'
                trigger={() => (
                  <button
                    disabled={
                      state.organisation === "" ||
                      state.name === "" ||
                      state.address === "" ||
                      state.charity === "" ||
                      state.name === "" ||
                      state.position === "" ||
                      state.tel === "" ||
                      state.email === "" ||
                      state.website === "" ||
                      state.amount === "" ||
                      state.liability === "" ||
                      state.safeguarding === "" ||
                      state.about === "" ||
                      state.project === "" ||
                      state.timeframe === "" ||
                      state.outreach === "" ||
                      state.demand === "" ||
                      state.impact === "" ||
                      state.evaluation === "" ||
                      expensesInputList[0].Cost === 0 ||
                      expensesInputList[0].Expenditure === "" ||
                      expensesInputList[0].Details === "" ||
                      incomeInputList[0].Income === "" ||
                      incomeInputList[0].Confirmed === "" ||
                      incomeInputList[0].Amount === 0 ||
                      incomeInputList.some((item) => !item.Clicked) ||
                      expensesInputList.some((item) => !item.Clicked)
                    }
                    style={{ width: "45%", color: "green", color: "white" }}
                    className='btn btn-info'
                  >
                    Save <FaSave />
                  </button>
                )}
                content={() => componentRef.current}
              />
              <button
                style={{ width: "45%", color: "red" }}
                className='button'
                type='button'
                onClick={props.closeModal}
              >
                Close
              </button>
            </div>
            <div style={{ display: "none" }}>
              {" "}
              <ComponentToPrint
                totalExpense={totalExpense}
                expenses={expensesInputList}
                income={incomeInputList}
                state={state}
                ref={componentRef}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplicationForm2;
